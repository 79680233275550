*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'montserrat', sans-serif !important;
    /* list-style: none; */
    
  }
  
  .ant-btn-primary {
    background-color: #1890ff !important;
  }
  .ant-btn-primary:hover {
    background-color: #40a9ff !important;
  }

  .ant-form-item-label>label{
    color: #01509A !important;
    font-size: 14px !important;
   }
   li{
    margin-bottom: 10px !important;
}

.ant-menu-title-content{
  font-size: 16px !important;
}

.ant-menu-item-selected{
  background-color: #1890ff !important;
  color: #fff !important;
  border-radius: 35px !important;
}

.view-file-button {
  margin-top: 20px;
}

.app-close-icon{
  background-color: #9ca3af !important;
  color: white !important;
  margin-right: 2.5rem;
  &:hover{
    color: white !important;
    background-color: #7f7f7f !important;
    border: none !important;
  }
}

.highlighted {
  background-color: yellow; /* Change to any color you want for highlighting */
}

@keyframes blink {
  0% { background-color: yellow; } /* Change to any color you want for blinking */
  50% { background-color: transparent; }
}

.blinking {
  animation: blink 1s infinite; /* Change the duration of blinking */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

::-webkit-scrollbar{
    width: 8px;
    height: 12px;
  }
  ::-webkit-scrollbar-track{
    background-color: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb{
    background-color: rgb(190, 190, 190);
  }
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #C4dBFA; /* Light gray background */
  }
  
  .login-content {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    width: 200px; /* Adjust as needed */
    margin-bottom: 20px;
    text-align: center !important;
  }
  
  .terms-privacy {
    font-size: 12px;
    color: #888; /* Lighter text color for secondary info */
  }
  